import React from "react"
import Menu from "../components/Menu"
import * as styles from "../styles/404.module.scss"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const NotFound = () => {
  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0)

  React.useEffect(() => {
    function handleResize() {
      if (isBrowser) setWidth(window.innerWidth)
    }
    if (isBrowser) window.addEventListener("resize", handleResize)
  })

  return (
    <>
      <Menu width={width} type="notfound" />
      <div className={styles.text}>
        <h1>Page Not Found</h1>
      </div>
    </>
  )
}

export default NotFound
